<template>
  <back-to-top v-bind="backToTopProps">
    <button type="button" class="btn icon secondary rounded">
      <inline-svg src="/img/icons/to-top.svg" />
    </button>
  </back-to-top>
</template>

<script>
  import BackToTop from '@/views/Widgets/BackToTop';
  import BreakpointsMixin from '@/views/Mixins/BreakpointsMixin.vue';

  export default {
    name: 'back-to-top-custom',
    mixins: [BreakpointsMixin],
    components: {
      BackToTop,
    },
    computed: {
      backToTopProps() {
        return {
          bottom: this.isSmallScreen ? '6rem' : '10rem',
          right: '2rem',
        };
      },
    },
  };
</script>
