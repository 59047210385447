<script>
  import moment from 'moment';
  import dao from '@/dao';

  export default {
    data () {
      return {
        isGrowthProgramUsersModalVisible: false,
        isGrowthProgramListModalVisible: false,
      };
    },
    methods: {
      closeGrowthModal() {
        this.isGrowthModalVisible = false;
      },
      closeGrowthProgramListModal() {
        this.isGrowthProgramListModalVisible = false;
      },
      closeGrowthProgramUsersModal() {
        this.isGrowthProgramUsersModalVisible = false;
      },
      displayTweetsStatusForUser(user) {
        if (user.areGrowthProgramThreadsFinished) {
          return 'Finished';
        } else {
          return `${user.growthProgramName} ${user.numberOfTweets} / ${user.numberOfGrowthSubProgramThreads}`;
        }
      },
      displayGrowthProgramDate(date) {
        return moment.unix(date.seconds).format('MMMM YYYY');
      },
      getCurrentGrowthProgram() {
        dao.growthProgram
          .getCurrentGrowthProgram()
          .then((growthProgram) => {
            this.switchToGrowthProgram({
              ref: growthProgram.ref,
              id: growthProgram.id,
              ...growthProgram.data(),
            });
            if (this.isWriterPage) {
              this.updateSelectedGrowthProgram(growthProgram.id);
            }
          })
          .catch(error => {
            console.error(error);
            alert('An error has occurred while getting the current growth program');
          });
      },
      getGrowthPrograms() {
        return dao.growthProgram
          .getGrowthPrograms()
          .then((result) => {
            if (!result.empty) {
              this.latestGrowthProgram = {
                ref: result.docs[0],
                ...result.docs[0].data(),
              };
              this.growthPrograms = result.docs.map((doc) => ({ ref: doc.ref, id: doc.id, ...doc.data() }));
            }
          })
          .catch((error) => {
            console.error(error);
            alert('An error has occurred while fetching growth programs');
          });
      },
      switchToGrowthProgram(growthProgram) {
        this.selectedGrowthProgram = growthProgram;
        this.setSelectedUser(null);
        this.isGrowthProgramUsersModalVisible = true;
      },
      setSelectedUser(user) {
        this.selectedUser = user;
      },
      showGrowthModal() {
        this.isGrowthModalVisible = true;
      },
      showGrowthProgramListModal() {
        this.isGrowthProgramListModalVisible = true;
      },
      showGrowthProgramUsersModal() {
        this.isGrowthProgramUsersModalVisible = true;
      },
    },
    name: 'growth-program-mixin',
  }
</script>

<style lang="scss" scoped>
  .program {
    background-color: transparent;
    color: #666;
    &:hover {
      background-color: #f1f1f1;
      .delete-icon {
        display: block !important;
      }
    }
  }
</style>